import React from 'react';
import './about.css';
import { AboutLandingPage, Vision, DebitCard, GoSatsTeamTitle, GoSatsTeam, Values } from '../../components';
import { Strings, Images, Href, Icons } from '../../Constants';

const communityButtonsData = [
    {
        LINKS: Href.GOSATS_TELEGRAM,
        BUTTON_CLASS_NAME: 'comButtom',
        ICON_CLASS_NAME: 'comTel',
        ICONS: Icons.FaTelegramPlane,
        BUTTON_TITLE: Strings.GOSATS_COMMUNITY_TELEGRAM_TITLE
    },
    {
        LINKS: Href.GOSATS_WHATSAPP,
        BUTTON_CLASS_NAME: 'comButtomWhatsApp',
        ICON_CLASS_NAME: 'comWhatsApp',
        ICONS: Icons.BsWhatsapp,
        BUTTON_TITLE: Strings.GOSATS_COMMUNITY_WHATSAPP_TITLE
    },
    {
        LINKS: Href.GOSATS_TWITTER,
        BUTTON_CLASS_NAME: 'comButtomTweet',
        ICON_CLASS_NAME: 'comTweet',
        ICONS: Icons.FaTwitter,
        BUTTON_TITLE: Strings.GOSATS_COMMUNITY_Twitter_TITLE
    },
]

const About = (props) => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    var TeamData = [
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673516684432",
            empName: "Kuldeep NB",
            empDesg: "VP, Engineering",
            linkedin: "https://www.linkedin.com/in/kuldeepnb/",
            twitter: "https://twitter.com/Kuldeep0393"
        },
        {
            empImage: "https://assets.gosats.io/notification/2024/7/1720690887598",
            empName: "Valesh Gopalam",
            empDesg: "Frontend Engineer",
            linkedin: "https://www.linkedin.com/in/valeshgopalam/",
            twitter: "https://x.com/0xvalesh"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673517993051",
            empName: "Ranjitha Salian",
            empDesg: "Q&A Engineer",
            linkedin: "https://www.linkedin.com/in/ranjitha-r-919248187",
            twitter: "https://twitter.com/Ranjitha_salian"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673517961523",
            empName: "Lohith MC",
            empDesg: "Junior Software Engineer",
            linkedin: "https://www.linkedin.com/in/lohith-m-c-b04b96175",
            twitter: "https://twitter.com/LohithMC2"
        },
        {
            empImage: "https://assets.gosats.io/notification/2024/4/1713511963894",
            empName: "Askar Kabeer",
            empDesg: "Software Engineer",
            linkedin: "www.linkedin.com/in/askar-kabeer-8b30a7223",
            twitter: "https://twitter.com/AskarKabeer_10"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/8/1692366224250",
            empName: "Aryan Vatsa",
            empDesg: "Product Designer",
            linkedin: "https://www.linkedin.com/in/aryan-vatsa-ab83b7195/",
            twitter: "https://twitter.com/arynvtsa"
        },
        {
            empImage: "https://assets.gosats.io/notification/2024/5/1715927883415",
            empName: "Priyanshu Verma",
            empDesg: "Graphic Designer",
            linkedin: "https://www.linkedin.com/in/priyanshuverma25/",
            twitter: "https://twitter.com/priyanshudesign"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/8/1692252926269",
            empName: "Denver Demien Lobo",
            empDesg: "Social Media Creator",
            linkedin: "https://www.linkedin.com/in/denver-lobo-7785b718a",
            // twitter: ""
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673518380957",
            empName: "Anchit Shah",
            empDesg: "Customer Delight",
            linkedin: "https://www.linkedin.com/in/anchit-shah-21144ba4",
            twitter: "https://twitter.com/Anchit_93"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/3/1679312266814",
            empName: "Sumaiya",
            empDesg: "Customer Delight",
            linkedin: "https://www.linkedin.com/in/sumaiya612",
            twitter: "https://twitter.com/Sumaiya_aziz612?t=EN4xyfCEQeomKy6d_UUaww&s=31"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673526885430",
            empName: "Sai Prathyush Talluri",
            empDesg: "Customer Delight",
            linkedin: "https://www.linkedin.com/in/talluri-sai-prathyush-220bab244",
            twitter: "https://mobile.twitter.com/tallurisaiprat1"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673518462905",
            empName: "Sudeep BN",
            empDesg: "Head of Partnerships & Alliances",
            linkedin: "https://www.linkedin.com/in/sudeepbn/",
            // twitter: ""
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/8/1692366141705",
            empName: "Karan Agarwal",
            empDesg: "VP, Product",
            linkedin: "https://www.linkedin.com/in/karan-ag/",
            twitter: "https://twitter.com/theKaranAgarwal"
        },
        {
            empImage: "https://assets.gosats.io/notification/2024/1/1704709792221",
            empName: "Punyata Dash",
            empDesg: "HR Associate",
            linkedin: "https://www.linkedin.com/in/punyata-dash-196656190/",
            // twitter: ""
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673518001404",
            empName: "Roshni Aslam",
            empDesg: "Co-Founder & Head of Finance",
            linkedin: "https://www.linkedin.com/in/roshni-aslam-b46a88130/",
            twitter: "https://twitter.com/withRoshniAslam"
        },
        {
            empImage: "https://assets.gosats.io/notification/2023/1/1673517995465",
            empName: "Mohammed Roshan",
            empDesg: "Co-Founder, CEO & CTO",
            linkedin: "https://www.linkedin.com/in/mohammed-roshan-25391a43/",
            twitter: "https://twitter.com/sfrdev"
        }
    ]

    var valuesData = [
        {
            textColor: "#FFFFFF",
            subTextColor: "#A6A7A8",
            shadow: "none",
            valuesTitle: 'Values We Live By',
            valuesSubtitle: "GoSats is on a mission to get rewards to every shopper, spender and saver. Join us on this journey to #StackSats with GoSats.",
            titleWidth: '180px',
            padLeft: '0px',
            mrgTop: '1rem'
        },
        {

            bgColor: "#C2D1FF",
            valuesImg: require("../../images/disagree.png"),
            valuesTitle: "Disagree And Commit",
            valuesSubtitle: "We strive to create an environment where one can respectfully challenge decisions that they disagree with. Once a decision is determined together, we commit wholly.",
            mrgTop: '4rem'
        },
        {
            bgColor: "#D1C8E5",
            valuesImg: require("../../images/ownership.png"),
            valuesTitle: "Ownership",
            valuesSubtitle: "We expect everyone on the team to be owners, and think on behalf of the company. People on our team are expected to focus on long-term value, rather than short-term results.",
            mrgTop: '7rem'
        },
        {
            bgColor: "#FFEDBE",
            valuesImg: require("../../images/high.png"),
            valuesTitle: "High Performance",
            valuesSubtitle: "We insist on high-performance from our team. This means effective execution - creating results with optimum use of time and resources, while applying the highest standards in every aspect of our work.",
            mrgTop: '-3rem'
        },
        {
            bgColor: "#FFCFC9",
            valuesImg: require("../../images/customer.png"),
            valuesTitle: "Customer Focus",
            valuesSubtitle: "We put customers at the heart of everything we do. There is an unwavering focus to understand and obsess over our customers, and most importantly retain their trust and keep them delighted!",
            mrgTop: '-1rem'
        },
        {
            bgColor: "#C7C1FF",
            valuesImg: require("../../images/resonance.png"),
            valuesTitle: "Resonance With The Vision",
            valuesSubtitle: "At GoSats, we are are in this to make India get access to hard assets are rewards on every spend they make.",
            mrgTop: '2rem'

        }
    ]

    return (
        <div>
            <AboutLandingPage />
            <Vision />
            <div className='value'>
                <ValuesSection data={valuesData} />
            </div>
            <GoSatsTeamTitle />
            <TeamSection data={TeamData} />
            <CommunitySection />
            {/* <div className='ms-3 debCardContainer'>
                <DebitCard />
            </div> */}
        </div>
    );
}

const ValuesSection = ({ data }) => {
    return (
        <div className='container valueContainer '>
            {/* <div className='vision-dots'>
                <img src={Images.VisionDots} className='visionBgImg' alt='TeamImage' />
            </div> */}
            <div className='d-flex flex-wrap justify-content-between valueSubContainer '>
                {
                    ValuesDetails(data)
                }
            </div>
            {/* <div className='vision-dots2'>
                <img src={Images.VisionDots} className='visionBgImg2' alt='TeamImage' />
            </div> */}
        </div>
    )
}

const ValuesDetails = (data) => {
    return data.map((obj, index) => {
        return <Values valuesDetails={obj} key={index} />
    })
}

const TeamSection = ({ data }) => {
    return (
        <div className='container d-flex flex-wrap justify-content-center TeamSectionContainer'>
            {
                TeamDetails(data)
            }
        </div>
    )
}

const TeamDetails = (data) => {
    return data.map((obj, index) => {
        return <GoSatsTeam empDetail={obj} key={index} />
    })
}

const CommunityButtons = ({ data }) => {
    return (
        <div className='comSocialmedia d-flex flex-wrap'>
            {
                data.map((data, index) => (
                    <div className='comButtonHolder' key={index}>
                        <a href={data.LINKS} target="_blank" rel="noreferrer">
                            <button className={data.BUTTON_CLASS_NAME}><data.ICONS className={data.ICON_CLASS_NAME} />{data.BUTTON_TITLE}</button>
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

const CommunitySection = () => {
    return (
        <div className='container footerSubSection'>
            <div className='footer-community d-flex flex-wrap align-items-center align-self-center'>
                <div className='footerImg-holder'>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='col-11 d-lg-none'>
                            <div className='comTitle'>{Strings.GOSATS_COMMUNITY_TITLE}</div>
                        </div>
                    </div>
                    <img src={Images.comImg} className='footerImgMob' alt='Social Media' />
                </div>
                <div className='footerContant-holder  col-lg-8'>
                    <div className='comTitle d-none d-lg-block'>{Strings.GOSATS_COMMUNITY_TITLE}</div>
                    <p className='comSubTitle  col-xl-7'>{Strings.GOSATS_COMMUNITY_SUBTITLE}</p>
                    <CommunityButtons data={communityButtonsData} />
                </div>
                <img src={Images.comImg} className='footerImg' alt='Social Media' />
            </div>
        </div>
    )
}

export default About;